import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Router } from "@angular/router";
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private Router: Router) {}

  canActivate(): boolean {
    let token = localStorage.getItem("token");
    let userid = localStorage.getItem("userid");
    let useraktivasi = localStorage.getItem("userisaktivasi");

    if (token == null || userid == null) {
      this.Router.navigate(["login"], { replaceUrl: true });
      return false;
    } else {
      if (useraktivasi == "0") {
        this.Router.navigate(["login"], { replaceUrl: true });
        console.log("0");
      } else if (useraktivasi == "1") {
        console.log("1");
        // this.Router.navigate(['tabs'], { replaceUrl: true });
      } else if (useraktivasi == "2") {
        console.log("2");
        this.Router.navigate(["uploadid"], { replaceUrl: true });
      } else if (useraktivasi == "3") {
        console.log("3");
        this.Router.navigate(["login"], { replaceUrl: true });
      } else if (useraktivasi == "4") {
        console.log("4");
        this.Router.navigate(["login"], { replaceUrl: true });
      } else {
      }

      return true;
    }
  }
}
