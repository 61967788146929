import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ApiService, Helper } from 'src/app/kiosdesa.service';


@Component({
  selector: 'app-konfirmasi',
  templateUrl: './konfirmasi.page.html',
  styleUrls: ['./konfirmasi.page.scss'],
})
export class KonfirmasiPage implements OnInit {

  constructor(private popover: PopoverController, public Router: Router, public ApiService: ApiService, public Helper: Helper) { }




  ionViewWillEnter() {

  }
  ngOnInit() {

  }






  ClosePopover() {
    this.popover.dismiss();
  }

}
