import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { KonfirmasiaktivasiPageRoutingModule } from './konfirmasiaktivasi-routing.module';

import { KonfirmasiaktivasiPage } from './konfirmasiaktivasi.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    KonfirmasiaktivasiPageRoutingModule
  ],
  declarations: [KonfirmasiaktivasiPage]
})
export class KonfirmasiaktivasiPageModule { }
