import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ApiService, Helper } from 'src/app/kiosdesa.service';



@Component({
  selector: 'app-datapopover',
  templateUrl: './datapopover.page.html',
  styleUrls: ['./datapopover.page.scss'],
})
export class DatapopoverPage implements OnInit {

  constructor(private popover: PopoverController, public Router: Router, public ApiService: ApiService, public Helper: Helper) { }

  produkharga = localStorage.getItem('produk_harga');
  produknama = localStorage.getItem('produk_nm');
  provider = localStorage.getItem('provider');

  ionViewWillEnter() {

  }
  ngOnInit() {

  }




  buttonkonfirmasi() {

    this.Router.navigate(['verificationcodepulsa']);

    this.popover.dismiss();
  }

  ClosePopover() {
    this.popover.dismiss();
  }

}
