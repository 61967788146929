import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';



@Component({
  selector: 'app-appupdate',
  templateUrl: './appupdate.page.html',
  styleUrls: ['./appupdate.page.scss'],
})
export class AppupdatePage implements OnInit {

  constructor(private popover: PopoverController, public Router: Router) { }




  ionViewWillEnter() {

  }
  ngOnInit() {

  }






  ClosePopover() {
    this.popover.dismiss();
  }

}
