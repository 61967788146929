import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';



@Component({
  selector: 'app-penarikan',
  templateUrl: './penarikan.page.html',
  styleUrls: ['./penarikan.page.scss'],
})
export class PenarikanPage implements OnInit {
  //format pattern
  DECIMAL_SEPARATOR = ".";
  GROUP_SEPARATOR = ",";

  // menu tarik uang
  norekeningtrkuang = localStorage.getItem('norekeningtrkuang');
  kode_banktrkuang = localStorage.getItem('kode_banktrkuang');
  nama_banktrkuang = localStorage.getItem('nama_banktrkuang');
  atas_namatrkuang = localStorage.getItem('atas_namatrkuang');
  denomtrkuang = localStorage.getItem('denomtrkuang');
  nominaltftrkuang = localStorage.getItem('nominaltftrkuang');
  biayaadmintrkuang = localStorage.getItem('biayaadmintrkuang');
  totaltrk = localStorage.getItem('totaltrkuang');

  //kondisi
  kondisipopover: any;

  //menu kirim uang
  norekeningkrmuang = localStorage.getItem('norekeningkrmuang');
  kode_bankkrmuang = localStorage.getItem('kode_bankkrmuang');
  atas_namakrmuang = localStorage.getItem('atas_namakrmuang');
  denomkrmuang = localStorage.getItem('denomkrmuang');
  beritatransferkrmuang = localStorage.getItem('beritatransferkrmuang');
  nominaltfkrmuang = localStorage.getItem('nominaltfkrmuang');
  biayaadminkrmuang = localStorage.getItem('biayaadminkrmuang');
  totalkrm = localStorage.getItem('totalkrmuang');


  constructor(private popover: PopoverController, public Router: Router) { }




  ionViewWillEnter() {
    //kirimuang
    this.nominaltfkrmuang = this.format(this.nominaltfkrmuang);
    this.biayaadminkrmuang = this.format(this.biayaadminkrmuang)
    this.totalkrm = this.format(this.totalkrm);
    this.kondisipopover = localStorage.getItem('popoverkondisi');
    // console.log(this.kondisipopover);
    //tarikuang
    this.nominaltftrkuang = this.format(this.nominaltftrkuang);
    this.biayaadmintrkuang = this.format(this.biayaadmintrkuang)
    this.totaltrk = this.format(this.totaltrk);
    this.kondisipopover = localStorage.getItem('popoverkondisi');
    // console.log(this.kondisipopover);


  }
  ngOnInit() {

  }

  format(valString) {
    if (!valString) {
      return '';
    }
    let val = valString.toString();
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
    return parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!parts[1] ? '' : this.DECIMAL_SEPARATOR + parts[1]);
  };

  unFormat(val) {
    if (!val) {
      return '';
    }
    val = val.replace(/^0+/, '');

    if (this.GROUP_SEPARATOR === ',') {
      return val.replace(/,/g, '');
    } else {
      return val.replace(/\./g, '');
    }
  };


  buttonkonfirmasi() {
    localStorage.setItem('kondisiverificationcode', '2');
    this.Router.navigate(['verificationcode']);


    this.popover.dismiss();

  }

  konfirmasikirimuang() {
    localStorage.setItem('kondisiverificationcode', '1');
    this.Router.navigate(['verificationcode']);


    this.popover.dismiss();
  }

  ClosePopover() {
    this.popover.dismiss();
  }

}
