import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';



@Component({
  selector: 'app-voucherpopover',
  templateUrl: './voucherpopover.page.html',
  styleUrls: ['./voucherpopover.page.scss'],
})
export class VoucherpopoverPage implements OnInit {

  constructor(private popover: PopoverController, public Router: Router) { }

  idpel: any = localStorage.getItem('idpel');
  nama: any = localStorage.getItem('namapel');
  status: any = localStorage.getItem('status');
  pokok: any = localStorage.getItem('pokok');
  denda: any = localStorage.getItem('denda');
  admin: any = localStorage.getItem('admin');
  period: any = localStorage.getItem('period');
  jml_bulan: any = localStorage.getItem('jml_bulan');
  tarif: any = localStorage.getItem('tarif');
  no_meter: any = localStorage.getItem('no_meter');
  meter: any = localStorage.getItem('meter');
  discount: any = localStorage.getItem('discount');
  total: any = localStorage.getItem('total');
  denom: any = localStorage.getItem('denom');


  ionViewWillEnter() {

  }
  ngOnInit() {

  }




  buttonkonfirmasi() {
    localStorage.setItem('kondisiverificationcode', '0');
    this.Router.navigate(['verificationcode']);

    this.popover.dismiss();
  }

  ClosePopover() {
    this.popover.dismiss();
  }

}
