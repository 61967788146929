import { Component, OnInit } from '@angular/core';
import { PopoverController, Events } from '@ionic/angular';
import { Router } from '@angular/router';



@Component({
  selector: 'app-contactpopover',
  templateUrl: './contactpopover.page.html',
  styleUrls: ['./contactpopover.page.scss'],
})
export class ContactpopoverPage implements OnInit {

  constructor(private popover: PopoverController, public Router: Router, public event: Events) { }



  datanomor = [];
  namapemiliknomor: any;
  default: any;
  isenabled = false;


  ionViewWillEnter() {
    var data = localStorage.getItem("multiplenomor");
    var multiplenomor = JSON.parse(data);
    this.default = multiplenomor._objectInstance.phoneNumbers[0].value,
      this.namapemiliknomor = multiplenomor._objectInstance.displayName;
    var i = 0;
    for (i = 0; i < multiplenomor._objectInstance.phoneNumbers.length; i++) {
      this.datanomor.push({
        nomor: multiplenomor._objectInstance.phoneNumbers[i].value,
        kondisi: false,
      });

    }
  }

  pilihpaket() {
    // console.log(this.default);
    this.isenabled = true;
  }

  pilihnomor() {


    this.event.publish('user:theo', this.default);
    this.Router.navigate(['pulsa']);
    this.popover.dismiss();







  }
  ngOnInit() {
  }






}
