import { Component, OnInit } from "@angular/core";
import { ApiService, Helper } from "src/app/kiosdesa.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-konfirmasiaktivasi",
  templateUrl: "./konfirmasiaktivasi.page.html",
  styleUrls: ["./konfirmasiaktivasi.page.scss"],
})
export class KonfirmasiaktivasiPage implements OnInit {
  isaktivasikondisi = localStorage.getItem("userisaktivasi");
  keterangan = localStorage.getItem("keteranganaktivasi");

  constructor(
    public Router: Router,
    public ApiService: ApiService,
    public Helper: Helper
  ) {}

  ionViewWillEnter() {}

  ngOnInit() {}

  public uploadid() {
    this.Router.navigate(["uploadid"], { replaceUrl: true });
  }

  public login() {
    this.Router.navigate(["login"], { replaceUrl: true });
  }
}
