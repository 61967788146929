import { Component, OnInit } from '@angular/core';
import { ApiService, Helper } from '../../kiosdesa.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-lupapassword',
  templateUrl: './lupapassword.page.html',
  styleUrls: ['./lupapassword.page.scss'],
})
export class LupapasswordPage implements OnInit {

  constructor(
    public ApiService: ApiService,
    public Helper: Helper,
    public Router: Router
  ) { }


  ngOnInit() {

  }



  public lupapassword() {
    let email = (document.getElementById('email') as HTMLInputElement).value;
    let no_hp = (document.getElementById('no_hp') as HTMLInputElement).value;
    if (email == '') {
      this.Helper.alert('Email belum diisi');
    } else if (no_hp == '') {
      this.Helper.alert('No Handphone belum diisi');
    } else {
      // console.log(email)
      // console.log(no_hp)
      this.ApiService.lupaPassword({
        email: email,
        no_hp: no_hp
      }).subscribe(
        data => {
          this.Helper.hideLoading();
          var res = JSON.parse(JSON.stringify(data));
          // console.log(JSON.stringify(data));
          if (data.hasOwnProperty('result')) {
            var xdata = res.result.message;
            // console.log(xdata);
            this.Helper.presentToast(xdata);
            this.Router.navigate(['login']);
            // setTimeout(() => {
            //   location.reload();
            // }, 2000);
          } else {
            this.Helper.presentToast(res.error.message);

          }
        },
        error => {
          this.Helper.hideLoading();
          // console.log(JSON.stringify(error));
        }
      );
    }
  }

  public backtologin() {
    this.Router.navigate(['login']);
  }




}
