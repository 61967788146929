import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./AuthGuard.service";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./tabs/tabs.module").then((m) => m.TabsPageModule),
      canActivate: [AuthGuard],
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "registerweb",
    loadChildren: () =>
      import("./pages/registerweb/registerweb.module").then((m) => m.RegisterwebPageModule),
  },
  //{
  //  path: "register",
  //  loadChildren: () =>
  //    import("./pages/register/register.module").then(
  //      (m) => m.RegisterPageModule
  //    ),
  //},
  {
    path: "verifikasi",
    loadChildren: () =>
      import("./pages/verifikasi/verifikasi.module").then(
        (m) => m.VerifikasiPageModule
      ),
  },
  {
    path: "kebijakanprivasi",
    loadChildren: () =>
      import("./pages/kebijakanprivasi/kebijakanprivasi.module").then(
        (m) => m.KebijakanprivasiPageModule
      ),
  },
  {
    path: "syaratketentuan",
    loadChildren: () =>
      import("./pages/syaratketentuan/syaratketentuan.module").then(
        (m) => m.SyaratketentuanPageModule
      ),
  },
  {
    path: "bantuan",
    loadChildren: () =>
      import("./pages/bantuan/bantuan.module").then((m) => m.BantuanPageModule),
  },
  {
    path: "about",
    loadChildren: () =>
      import("./pages/about/about.module").then((m) => m.AboutPageModule),
  },
  {
    path: "pusatbantuan",
    loadChildren: () =>
      import("./pages/pusatbantuan/pusatbantuan.module").then(
        (m) => m.PusatbantuanPageModule
      ),
  },
  {
    path: "pusatbantuandetail",
    loadChildren: () =>
      import("./components/pusatbantuandetail/pusatbantuandetail.module").then(
        (m) => m.PusatbantuandetailPageModule
      ),
  },
  {
    path: "lupapassword",
    loadChildren: () =>
      import("./pages/lupapassword/lupapassword.module").then(
        (m) => m.LupapasswordPageModule
      ),
  },
  {
    path: "lupapintransaksi",
    loadChildren: () =>
      import("./pages/lupapintransaksi/lupapintransaksi.module").then(
        (m) => m.LupapintransaksiPageModule
      ),
  },
  {
    path: "setprofile",
    loadChildren: () =>
      import("./pages/setprofile/setprofile.module").then(
        (m) => m.SetprofilePageModule
      ),
  },
  {
    path: "setpassword",
    loadChildren: () =>
      import("./pages/setpassword/setpassword.module").then(
        (m) => m.SetpasswordPageModule
      ),
  },
  {
    path: "uploadid",
    loadChildren: () =>
      import("./pages/uploadid/uploadid.module").then(
        (m) => m.UploadidPageModule
      ),
  },
  {
    path: "konfirmasiaktivasi",
    loadChildren: () =>
      import(
        "./components/popovers/konfirmasiaktivasi/konfirmasiaktivasi.module"
      ).then((m) => m.KonfirmasiaktivasiPageModule),
  },
  {
    path: "pulsa",
    loadChildren: () =>
      import("./pages/kategori/pulsa/pulsa.module").then(
        (m) => m.PulsaPageModule
      ),
  },
  {
    path: "popover",
    loadChildren: () =>
      import("./components/popovers/popover/popover.module").then(
        (m) => m.PopoverPageModule
      ),
  },

  {
    path: "penarikan",
    loadChildren: () =>
      import("./components/popovers/penarikan/penarikan.module").then(
        (m) => m.PenarikanPageModule
      ),
  },
  {
    path: "detailtransaksi",
    loadChildren: () =>
      import(
        "./components/history/detailtransaksi/detailtransaksi.module"
      ).then((m) => m.DetailtransaksiPageModule),
  },
  {
    path: "detailtopup",
    loadChildren: () =>
      import("./components/history/detailtopup/detailtopup.module").then(
        (m) => m.DetailtopupPageModule
      ),
  },
  {
    path: "detailwithdraw",
    loadChildren: () =>
      import("./components/history/detailwithdraw/detailwithdraw.module").then(
        (m) => m.DetailwithdrawPageModule
      ),
  },
  {
    path: "vatopup",
    loadChildren: () =>
      import("./components/vatopup/vatopup.module").then(
        (m) => m.VatopupPageModule
      ),
  },
  {
    path: "print",
    loadChildren: () =>
      import("./components/print/print.module").then((m) => m.PrintPageModule),
  },
  {
    path: "printpreview",
    loadChildren: () =>
      import("./components/printpreview/printpreview.module").then(
        (m) => m.PrintpreviewPageModule
      ),
  },
  {
    path: "penarikandetail",
    loadChildren: () =>
      import("./components/penarikandetail/penarikandetail.module").then(
        (m) => m.PenarikandetailPageModule
      ),
  },
  {
    path: "game",
    loadChildren: () =>
      import("./pages/subkategori/game/game.module").then(
        (m) => m.GamePageModule
      ),
  },
  {
    path: "inettvkabel",
    loadChildren: () =>
      import("./pages/subkategori/inettvkabel/inettvkabel.module").then(
        (m) => m.InettvkabelPageModule
      ),
  },
  {
    path: "mobilelegend",
    loadChildren: () =>
      import("./pages/subkategori/mobilelegend/mobilelegend.module").then(
        (m) => m.MobilelegendPageModule
      ),
  },
  {
    path: "emoney",
    loadChildren: () =>
      import("./pages/subkategori/emoney/emoney.module").then(
        (m) => m.EmoneyPageModule
      ),
  },
  {
    path: "datapopover",
    loadChildren: () =>
      import("./components/popovers/datapopover/datapopover.module").then(
        (m) => m.DatapopoverPageModule
      ),
  },
  {
    path: "pulsapopover",
    loadChildren: () =>
      import("./components/popovers/pulsapopover/pulsapopover.module").then(
        (m) => m.PulsapopoverPageModule
      ),
  },
  {
    path: "konfirmasi",
    loadChildren: () =>
      import("./components/popovers/konfirmasi/konfirmasi.module").then(
        (m) => m.KonfirmasiPageModule
      ),
  },
  {
    path: "popoverprint",
    loadChildren: () =>
      import("./components/popovers/popoverprint/popoverprint.module").then(
        (m) => m.PopoverprintPageModule
      ),
  },
  {
    path: "appupdate",
    loadChildren: () =>
      import("./components/popovers/appupdate/appupdate.module").then(
        (m) => m.AppupdatePageModule
      ),
  },
  {
    path: "gamepopover",
    loadChildren: () =>
      import("./components/popovers/gamepopover/gamepopover.module").then(
        (m) => m.GamepopoverPageModule
      ),
  },
  {
    path: "contactpopover",
    loadChildren: () =>
      import("./components/popovers/contactpopover/contactpopover.module").then(
        (m) => m.ContactpopoverPageModule
      ),
  },
  {
    path: "voucherpopover",
    loadChildren: () =>
      import("./components/popovers/voucherpopover/voucherpopover.module").then(
        (m) => m.VoucherpopoverPageModule
      ),
  },
  {
    path: "verificationcode",
    loadChildren: () =>
      import("./components/verificationcode/verificationcode.module").then(
        (m) => m.VerificationcodePageModule
      ),
  },
  {
    path: "verificationcodepulsa",
    loadChildren: () =>
      import(
        "./components/verificationcodepulsa/verificationcodepulsa.module"
      ).then((m) => m.VerificationcodepulsaPageModule),
  },
  {
    path: "loginwallet",
    loadChildren: () =>
      import("./pages/wallet/loginwallet/loginwallet.module").then(
        (m) => m.LoginwalletPageModule
      ),
  },
  {
    path: "topup",
    loadChildren: () =>
      import("./pages/wallet/topup/topup.module").then(
        (m) => m.TopupPageModule
      ),
  },
  {
    path: "tarik",
    loadChildren: () =>
      import("./pages/wallet/tarik/tarik.module").then(
        (m) => m.TarikPageModule
      ),
  },

  {
    path: "pln",
    loadChildren: () =>
      import("./pages/kategori/pln/pln.module").then((m) => m.PlnPageModule),
  },
  {
    path: "telkom",
    loadChildren: () =>
      import("./pages/kategori/telkom/telkom.module").then(
        (m) => m.TelkomPageModule
      ),
  },
  {
    path: "bpjs",
    loadChildren: () =>
      import("./pages/kategori/bpjs/bpjs.module").then((m) => m.BpjsPageModule),
  },
  {
    path: "kirimuang",
    loadChildren: () =>
      import("./pages/kategori/kirimuang/kirimuang.module").then(
        (m) => m.KirimuangPageModule
      ),
  },
  {
    path: "lainnya",
    loadChildren: () =>
      import("./pages/kategori/lainnya/lainnya.module").then(
        (m) => m.LainnyaPageModule
      ),
  },
  {
    path: "internettvkabel",
    loadChildren: () =>
      import("./pages/kategori/internettvkabel/internettvkabel.module").then(
        (m) => m.InternettvkabelPageModule
      ),
  },
  {
    path: "pgn",
    loadChildren: () =>
      import("./pages/kategori/pgn/pgn.module").then((m) => m.PgnPageModule),
  },
  {
    path: "pdam",
    loadChildren: () =>
      import("./pages/kategori/pdam/pdam.module").then((m) => m.PdamPageModule),
  },
  {
    path: "multifinance",
    loadChildren: () =>
      import("./pages/kategori/multifinance/multifinance.module").then(
        (m) => m.MultifinancePageModule
      ),
  },
  {
    path: "vouchergame",
    loadChildren: () =>
      import("./pages/kategori/vouchergame/vouchergame.module").then(
        (m) => m.VouchergamePageModule
      ),
  },
  {
    path: "voucheremoney",
    loadChildren: () =>
      import("./pages/kategori/voucheremoney/voucheremoney.module").then(
        (m) => m.VoucheremoneyPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
