import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { GamepopoverPageRoutingModule } from './gamepopover-routing.module';

import { GamepopoverPage } from './gamepopover.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    GamepopoverPageRoutingModule
  ],
  declarations: [GamepopoverPage]
})
export class GamepopoverPageModule { }
