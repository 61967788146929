import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';



@Component({
  selector: 'app-pulsapopover',
  templateUrl: './pulsapopover.page.html',
  styleUrls: ['./pulsapopover.page.scss'],
})
export class PulsapopoverPage implements OnInit {

  constructor(private popover: PopoverController, public Router: Router) { }

  produkharga = localStorage.getItem('produk_harga');
  produknama = localStorage.getItem('produk_nm');
  provider = localStorage.getItem('provider');


  ionViewWillEnter() {

  }
  ngOnInit() {

  }




  buttonkonfirmasi() {

    this.Router.navigate(['verificationcodepulsa']);

    this.popover.dismiss();
  }

  ClosePopover() {
    this.popover.dismiss();
  }

}
