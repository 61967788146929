import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AlertController, LoadingController } from "@ionic/angular";
import { ToastController } from "@ionic/angular";
import { Router } from "@angular/router";

import * as moment from 'moment';
import * as CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  baseUrl: string = "https://api.kiosdesa.com/";
  AlertController: any;
  token: string = localStorage.getItem("token");
  userid: string = localStorage.getItem("userid");
  emailverifikasi: string = localStorage.getItem("emailverifikasi");
  walletid: string = localStorage.getItem("no_telp");
  produkid: string = localStorage.getItem("produk_id");
  device_id: string = localStorage.getItem("device_id");
  device_name: string = localStorage.getItem("device_name");

  //header
  api_key: string =
    "pf4TVUnCPA0yPaOgRjyJVMsXoL9LzgzZrD5FGAvQBlEHeivsU1mI6nkCKWEbbQo2";
  username: string = "ws";

  timestamp = "";
  signature = "";

  constructor(private httpClient: HttpClient) {}

  //token: string = localStorage.getItem("token");
  //userid: string = localStorage.getItem("userid");
  //emailverifikasi: string = localStorage.getItem("emailverifikasi");
  //walletid: string = localStorage.getItem("no_telp");
  //produkid: string = localStorage.getItem("produk_id");
  //device_id: string = localStorage.getItem("device_id");
  //device_name: string = localStorage.getItem("device_name");

  public userLogin(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "userLogin",
      params: {
        data: {
          email: xpar.notelpon,
          password: xpar.password,
        },
      },
      id: 1,
    };
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public userRegister(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "userRegister",
      params: {
        data: {
          username: xpar.username,
          email: xpar.email,
          password: xpar.password,
          no_telp: xpar.notelpon,
          nama: xpar.nama,
          tgl_lahir: xpar.tgl_lahir,
          jenis_kelamin: xpar.jenis_kelamin,
        },
      },
      id: 1,
    };
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getWallet() {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "getWallet",
      params: {
        data: [
          {
            wallet_id: this.walletid,
            token: this.token,
            user_id: this.userid,
            device_id: this.device_id,
            device_name: this.device_name,
          },
        ],
      },
      id: "1",
    };
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public setwallet(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "setWallet",
      params: {
        data: [
          {
            wallet_id: this.walletid,
            token: this.token,
            user_id: this.userid,
            pin: xpar.pintransaksi1,
            ulangi_pin: xpar.pintransaksi2,
            device_id: this.device_id,
            device_name: this.device_name,
          },
        ],
      },
      id: "1",
    };

    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public walletLogin(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "walletLogin",
      params: {
        data: [
          {
            wallet_id: xpar.nohandphone,
            pin: xpar.pintransaksi,
            token: this.token,
            user_id: this.userid,
          },
        ],
      },
      id: 1,
    };
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getHistory() {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "getHistory",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
          },
        ],
      },
      id: "1",
    };

    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getMetodepembayaran() {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "getMetodePembayaran",
      params: {
        data: [
          {
            token: this.token,
          },
        ],
      },
      id: "1",
    };

    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getPulsa(value) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "getPulsa",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
            id_pel: value.notelpon,
          },
        ],
      },
      id: "1",
    };
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public setPulsa(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "setPulsa",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
            id_pel: xpar.notelpon,
            produk_id: xpar.produk_id,
            pin: xpar.pintransaksi,
          },
        ],
      },
      id: "1",
    };
    console.log(par);
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getDenom(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "getDenom",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
            kategori_id: xpar.idkategori,
            denom_depan: xpar.denom_depan,
          },
        ],
      },
      id: "1",
    };
    console.log(par);
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getInquiry(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      id: 1,
      method: "getInquiry",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
            denom: xpar.denom,
            id_pel: xpar.id_pel,
          },
        ],
      },
    };
    console.log(par);
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getInquirykirimuang(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      id: 1,
      method: "getInquiry",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
            denom: xpar.denom,
            id_pel: xpar.id_pel,
          },
        ],
      },
    };
    console.log(par);
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }
  public getCetakBukti(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp.toString(),
      signature: this.signature,
    });
    var par = {
      jsonrpc: "2.0",
      method: "getCetakBukti",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
            biaya_admin: xpar.biayaadmin,
            no_ref: xpar.no_ref,
          },
        ],
      },
      id: "1",
    };

    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public setTransferBank(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "setTransferBank",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
            id_pel: xpar.nomorpelanggan,
            produk_id: xpar.produk_id,
            pin: xpar.pintransaksi,
            nominal: xpar.nominal,
            berita_transfer: xpar.berita_transfer,
          },
        ],
      },
      id: "1",
    };

    console.log(par);
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public setInvoiceBill(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "setInvoiceBill",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
            id_pel: xpar.nomorpelanggan,
            produk_id: xpar.produk_id,
            pin: xpar.pintransaksi,
          },
        ],
      },
      id: "1",
    };

    console.log(par);
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getInvoice() {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "getInvoice",
      params: {
        data: {
          token: this.token,
          user_id: this.userid,
          kategori_tipe: "bill",
        },
      },
      id: 1,
    };
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public setTopup(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "setTopup",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
            id_pel: this.walletid,
            nominal: xpar.nominal,
            metodebayar_id: xpar.metodebayar_id,
            metodebayar_sub_id: xpar.metodebayar_sub_id,
          },
        ],
      },
      id: "1",
    };
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public setTopupByVoucher(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "setTopupByVoucher",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
            id_pel: this.walletid,
            kd_voucher: xpar.kd_voucher,
          },
        ],
      },
      id: "1",
    };
    console.log(par);
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getSaldo() {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "getSaldo",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
            id_pel: this.walletid,
          },
        ],
      },
      id: "1",
    };
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getHistoryTrx() {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "getHistoryTrx",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
          },
        ],
      },
      id: "1",
    };

    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getHistoryTopup() {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "getHistoryTopup",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
          },
        ],
      },
      id: "1",
    };

    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getHistoryWithdraw() {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "getHistoryWithdraw",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
          },
        ],
      },
      id: "1",
    };
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getCustomerJourney(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "getCustomerJourney",
      params: {
        data: {
          denom: xpar.denom,
        },
      },
      id: "1",
    };
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getBank() {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "getBank",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
          },
        ],
      },
      id: "1",
    };
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public setWithdraw(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "setWithdraw",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
            bank_name: xpar.bank_name,
            bank_code: xpar.bank_code,
            no_rekening: xpar.no_rekening,
            nama_rekening: xpar.nama_rekening,
            nominal: xpar.nominal,
            id_pel: xpar.nomorpelanggan,
            produk_id: xpar.produk_id,
            pin: xpar.pintransaksi,
          },
        ],
      },
      id: "1",
    };
    console.log(par);
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public setProfil(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "setProfil",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
            nama: xpar.nama,
            tgl_lahir: xpar.tgl_lahir,
            jenis_kelamin: xpar.jenis_kelamin,
          },
        ],
      },
      id: "1",
    };
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public setPassword(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "setPassword",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
            password_lama: xpar.password_lama,
            password_baru: xpar.password_baru,
            konfirmasi_pass: xpar.konfirmasi_pass,
          },
        ],
      },
      id: "1",
    };
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public lupaPassword(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "lupaPassword",
      params: {
        data: {
          email: xpar.email,
          no_hp: xpar.no_hp,
        },
      },
      id: 1,
    };
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public lupaPintransaksi(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "lupaPinTransaksi",
      params: {
        data: {
          email: xpar.email,
          no_hp: xpar.no_hp,
        },
      },
      id: 1,
    };
    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getProfil() {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "getProfil",
      params: {
        data: [
          {
            token: this.token,
            user_id: this.userid,
          },
        ],
      },
      id: "1",
    };

    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public aktivasiAkun(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "aktivasiAkun",
      params: {
        data: {
          email: xpar.email,
          kode_aktivasi: xpar.kode_aktivasi,
        },
      },
      id: "1",
    };
    console.log(par);

    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public resendAktivasi(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "resendAktivasi",
      params: {
        data: {
          email: xpar.email,
        },
      },
      id: "1",
    };

    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }

  public getVersion() {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp,
      signature: this.signature,
    });

    var par = {
      jsonrpc: "2.0",
      method: "getVersion",
      id: "1",
    };

    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }
  public uploadID(xpar) {
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );
    var str = utc_timestamp.toString();
    this.timestamp = str.substr(0, 10);

    var hash = CryptoJS.HmacSHA256(
      this.username + "&" + this.timestamp,
      this.api_key
    );
    this.signature = hash.toString(CryptoJS.enc.Base64);

    let headers = new HttpHeaders({
      userid: this.username,
      key: this.timestamp.toString(),
      signature: this.signature,
    });
    var par = {
      jsonrpc: "2.0",
      method: "uploadID",
      params: {
        data: {
          token: xpar.token,
          user_id: xpar.userid,
          img_id: xpar.img_id,
          img_foto: xpar.img_foto,
        },
      },
      id: "1",
    };
    console.log(par);

    return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
  }
}

export class Helper {
  isLoading = false;
  istoast = false;
  constructor(
    private AlertController: AlertController,
    public router: Router,
    public LoadingController: LoadingController,
    private toastController: ToastController
  ) {}

  async presentToast(message: any) {
    this.istoast = true;
    return await this.toastController
      .create({
        message: message,
        duration: 3000,
        position: "top",
        color: "dark",
      })
      .then((a) => {
        a.present().then(() => {
          console.log("toast");
          if (!this.istoast) {
            a.dismiss().then(() => console.log("finish"));
          }
        });
      });
  }

  async hideToast() {
    this.istoast = false;
  }

  async alert(msg) {
    const alert = await this.AlertController.create({
      message: msg,
      buttons: ["OK"],
    });
    await alert.present();
  }

  public cekToken() {
    let token = localStorage.getItem("token");
    let userid = localStorage.getItem("userid");
    if (token == null || userid == null) {
      this.router.navigate(["login"]);
    }
  }
  public cekSaldo() {
    let wallet_saldo = localStorage.getItem("wallet_saldo");

    if (wallet_saldo == null) {
      this.router.navigate(["loginwallet"]);
    }
  }

  public isLogin() {
    let token = localStorage.getItem("token");
    let userid = localStorage.getItem("userid");
    if (token == null || userid == null) {
      this.router.navigate(["login"]);
      return false;
    } else {
      return true;
    }
  }

  async showLoading() {
    this.isLoading = true;
    return await this.LoadingController.create({
      message: "Please Wait",
      backdropDismiss: true,
    }).then((a) => {
      a.present().then(() => {
        console.log("loading");
        if (!this.isLoading) {
          a.dismiss().then(() => console.log("finish"));
        }
      });
    });
  }

  async hideLoading() {
    this.isLoading = false;
    this.LoadingController.dismiss();
  }
}
