import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ContactpopoverPageRoutingModule } from './contactpopover-routing.module';

import { ContactpopoverPage } from './contactpopover.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ContactpopoverPageRoutingModule
  ],
  declarations: [ContactpopoverPage]
})
export class ContactpopoverPageModule { }
