import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { ApiService, Helper } from "./kiosdesa.service";
import { AuthGuard } from "./AuthGuard.service";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";

import { Contacts } from "@ionic-native/contacts/ngx";
import { PopoverPageModule } from "./components/popovers/popover/popover.module";
import { PulsapopoverPageModule } from "./components/popovers/pulsapopover/pulsapopover.module";
import { GamepopoverPageModule } from "./components/popovers/gamepopover/gamepopover.module";
import { ContactpopoverPageModule } from "./components/popovers/contactpopover/contactpopover.module";
import { VoucherpopoverPageModule } from "./components/popovers/voucherpopover/voucherpopover.module";
import { DatapopoverPageModule } from "./components/popovers/datapopover/datapopover.module";
import { PenarikanPageModule } from "./components/popovers/penarikan/penarikan.module";
import { LupapasswordPageModule } from "./pages/lupapassword/lupapassword.module";
import { Device } from "@ionic-native/device/ngx";
import { IonicStorageModule } from "@ionic/storage";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { Clipboard } from "@ionic-native/clipboard/ngx";

import { LottieAnimationViewModule } from "ng-lottie";
import { KonfirmasiPageModule } from "./components/popovers/konfirmasi/konfirmasi.module";
import { KonfirmasiaktivasiPageModule } from "./components/popovers/konfirmasiaktivasi/konfirmasiaktivasi.module";
import { PopoverprintPageModule } from "./components/popovers/popoverprint/popoverprint.module";
import { IonicSelectableModule } from "ionic-selectable";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { AppupdatePageModule } from "./components/popovers/appupdate/appupdate.module";
import { BluetoothSerial } from "@ionic-native/bluetooth-serial/ngx";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { File } from "@ionic-native/file/ngx";

import { Camera } from "@ionic-native/camera/ngx";
import { CameraPreview } from "@ionic-native/camera-preview/ngx";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    PopoverprintPageModule,
    PopoverPageModule,
    PulsapopoverPageModule,
    KonfirmasiaktivasiPageModule,
    LupapasswordPageModule,
    KonfirmasiPageModule,
    AppupdatePageModule,
    GamepopoverPageModule,
    ContactpopoverPageModule,
    VoucherpopoverPageModule,
    DatapopoverPageModule,
    PenarikanPageModule,
    HttpClientModule,
    LottieAnimationViewModule,
    IonicSelectableModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    ApiService,
    AppVersion,
    BluetoothSerial,
    Helper,
    File,
    FileOpener,
    Camera,
    CameraPreview,
    AuthGuard,
    InAppBrowser,
    Device,
    Clipboard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Contacts,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
