import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';



@Component({
  selector: 'app-gamepopover',
  templateUrl: './gamepopover.page.html',
  styleUrls: ['./gamepopover.page.scss'],
})
export class GamepopoverPage implements OnInit {

  constructor(private popover: PopoverController, public Router: Router) { }

  produkharga = localStorage.getItem('produk_harga');
  produknama = localStorage.getItem('produk_nm');
  produkkd = localStorage.getItem('produk_kd');
  kondisipopover = localStorage.getItem('kondisipopovergame&emoney');


  ionViewWillEnter() {

  }
  ngOnInit() {

  }




  buttonkonfirmasi() {

    this.Router.navigate(['verificationcodepulsa']);

    this.popover.dismiss();
  }

  ClosePopover() {
    this.popover.dismiss();
  }

}
