import { Component, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { Router } from "@angular/router";
import { ApiService, Helper } from "src/app/kiosdesa.service";
import { PrintService } from "../../../print.service";

@Component({
  selector: "app-popoverprint",
  templateUrl: "./popoverprint.page.html",
  styleUrls: ["./popoverprint.page.scss"],
})
export class PopoverprintPage implements OnInit {
  constructor(
    private popover: PopoverController,
    public Router: Router,
    public ApiService: ApiService,
    public Helper: Helper,
    private print: PrintService
  ) {}

  bluetoothList: any = [];
  selectedPrinter: any;
  printinvoice = JSON.parse(localStorage.getItem("printinvoice"));
  filepdf = localStorage.getItem("filepdf");
  ionViewWillEnter() {
    //alert(22);
    this.listPrinter();
    // console.log(this.printinvoice);
  }
  ngOnInit() {
    // console.log(this.filepdf);
  }

  //This will list all of your bluetooth devices
  listPrinter() {
    this.print.searchBluetoothPrinter().then((resp) => {
      //List of bluetooth device list
      this.bluetoothList = resp;
      // alert(JSON.stringify(resp));
    });
  }
  //This will store selected bluetooth device mac address
  selectPrinter(macAddress) {
    //Selected printer macAddress stored here
    this.selectedPrinter = macAddress;
    this.printStuff();
  }

  //This will print
  /*printStuff() {
    if (this.printinvoice.bukti == "TRANSAKSI KIRIM UANG") {
      var myText =
        "\x1b\x4d\x01\x1b\x61\x00ARTPAY" +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01" +
        this.printinvoice.member_name +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01BUKTI PEMBAYARAN" +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01" +
        this.printinvoice.bukti +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n\n" +
        "\x1b\x61\x00\x1b\x4d\x01TANGGAL : " +
        this.printinvoice.date_trx +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01ID PEL  : " +
        this.printinvoice.id_pel +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01NAMA PENGIRIM  : " +
        this.printinvoice.nama_pengirim +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01REF  : " +
        this.printinvoice.no_ref +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01BIAYA ADMIN : " +
        this.printinvoice.biaya_admin +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01TOTAL BAYAR : " +
        this.printinvoice.total_bayar +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01BERITA TRANSFER : " +
        this.printinvoice.berita_transfer +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n\n" +
        "\x1b\x61\x00\x1b\x4d\x01BANK PENERIMA : " +
        this.printinvoice.bank_penerima +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01NAMA PENERIMA  : " +
        this.printinvoice.nama_penerima +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n\n" +
        "\x1b\x61\x01\x1b\x4d\x01" +
        this.printinvoice.informasi +
        "\x1b\x4d\x01\x1b\x61\x01" +
        "\n";
    } else if (this.printinvoice.bukti == "PLN PRABAYAR") {
      var myText =
        "\x1b\x4d\x01\x1b\x61\x00ARTPAY" +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01" +
        this.printinvoice.member_name +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01BUKTI PEMBAYARAN" +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01" +
        this.printinvoice.bukti +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n\n" +
        "\x1b\x61\x00\x1b\x4d\x01TANGGAL : " +
        this.printinvoice.date_trx +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01ID PEL  : " +
        this.printinvoice.id_pel +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01NAMA   : " +
        this.printinvoice.nama +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01TARIF DAYA  : " +
        this.printinvoice.tarif_daya +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01Kwh : " +
        this.printinvoice.kwh +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01REF  : " +
        this.printinvoice.no_ref +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01NOMINAL : " +
        this.printinvoice.nominal +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01BIAYA ADMIN : " +
        this.printinvoice.biaya_admin +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01TOTAL BAYAR : " +
        this.printinvoice.total_bayar +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n\n" +
        "\x1b\x61\x00\x1b\x4d\x01TOKEN : " +
        this.printinvoice.token +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n\n" +
        "\x1b\x61\x01\x1b\x4d\x01 STRUK INI MERUPAKAN BUKTI TRANSAKSI YANG SAH, MOHON DISIMPAN \x1b\x4d\x01\x1b\x61\x01" +
        "\n\n" +
        "\x1b\x61\x01\x1b\x4d\x01" +
        this.printinvoice.informasi +
        "\x1b\x4d\x01\x1b\x61\x01" +
        "\n";
    } else if (
      this.printinvoice.informasi == "null" ||
      this.printinvoice.informasi == "undefined"
    ) {
      var myText =
        "\x1b\x4d\x01\x1b\x61\x00ARTPAY" +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01" +
        this.printinvoice.member_name +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01BUKTI PEMBAYARAN" +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01" +
        this.printinvoice.bukti +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n\n" +
        "\x1b\x61\x00 \x1b\x45\x01TANGGAL : " +
        this.printinvoice.date_trx +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00 \x1b\x45\x01ID PEL : " +
        this.printinvoice.id_pel +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00 \x1b\x45\x01NAMA : " +
        this.printinvoice.nama +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00 \x1b\x45\x01REF  : " +
        this.printinvoice.no_ref +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00 \x1b\x45\x01TAGIHAN : " +
        this.printinvoice.tagihan +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00 \x1b\x45\x01BIAYA ADMIN : " +
        this.printinvoice.biaya_admin +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00 \x1b\x45\x01TOTAL BAYAR : " +
        this.printinvoice.total_bayar +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n\n" +
        "\x1b\x61\x01\x1b\x4d\x01 STRUK INI MERUPAKAN BUKTI TRANSAKSI YANG SAH, MOHON DISIMPAN \x1b\x4d\x01\x1b\x61\x01" +
        "\n";
    } else {
      var myText =
        "\x1b\x4d\x01\x1b\x61\x00ARTPAY" +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01" +
        this.printinvoice.member_name +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01BUKTI PEMBAYARAN" +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00\x1b\x4d\x01" +
        this.printinvoice.bukti +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n\n" +
        "\x1b\x61\x00 \x1b\x45\x01TANGGAL : " +
        this.printinvoice.date_trx +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00 \x1b\x45\x01ID PEL : " +
        this.printinvoice.id_pel +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00 \x1b\x45\x01NAMA : " +
        this.printinvoice.nama +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00 \x1b\x45\x01REF  : " +
        this.printinvoice.no_ref +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00 \x1b\x45\x01TAGIHAN : " +
        this.printinvoice.tagihan +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00 \x1b\x45\x01BIAYA ADMIN : " +
        this.printinvoice.biaya_admin +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n" +
        "\x1b\x61\x00 \x1b\x45\x01TOTAL BAYAR : " +
        this.printinvoice.total_bayar +
        "\x1b\x4d\x01\x1b\x61\x00" +
        "\n\n" +
        "\x1b\x61\x01\x1b\x4d\x01 STRUK INI MERUPAKAN BUKTI TRANSAKSI YANG SAH, MOHON DISIMPAN \x1b\x4d\x01\x1b\x61\x01" +
        "\n\n" +
        "\x1b\x61\x01\x1b\x4d\x01" +
        this.printinvoice.informasi +
        "\x1b\x4d\x01\x1b\x61\x01" +
        "\n";
    }

    this.print.sendToBluetoothPrinter(this.selectedPrinter, myText);
    this.popover.dismiss();
  }*/
  printStuff() {
    if (this.printinvoice.bukti == "TRANSAKSI KIRIM UANG") {
      var myText =
        '\x1b\x4d\x01\x1b\x61\x00KIOSDESA' + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01' + this.printinvoice.member_name + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01BUKTI PEMBAYARAN' + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01' + this.printinvoice.bukti + '\x1b\x4d\x01\x1b\x61\x00' + "\n\n"
        + '\x1b\x61\x00\x1b\x4d\x01TANGGAL : ' + this.printinvoice.date_trx + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01ID PEL  : ' + this.printinvoice.id_pel + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01NAMA PENGIRIM  : ' + this.printinvoice.nama_pengirim + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01REF  : ' + this.printinvoice.no_ref + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01BIAYA ADMIN : ' + this.printinvoice.biaya_admin + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01TOTAL BAYAR : ' + this.printinvoice.total_bayar + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01BERITA TRANSFER : ' + this.printinvoice.berita_transfer + '\x1b\x4d\x01\x1b\x61\x00' + "\n\n"
        + '\x1b\x61\x00\x1b\x4d\x01BANK PENERIMA : ' + this.printinvoice.bank_penerima + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01NAMA PENERIMA  : ' + this.printinvoice.nama_penerima + '\x1b\x4d\x01\x1b\x61\x00' + "\n\n"
        + '\x1b\x61\x01\x1b\x4d\x01' + this.printinvoice.informasi + '\x1b\x4d\x01\x1b\x61\x01' + "\n"

    } else if (this.printinvoice.bukti == "PLN PRABAYAR") {
      var myText =
        '\x1b\x4d\x01\x1b\x61\x00KIOSDESA' + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01' + this.printinvoice.member_name + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01BUKTI PEMBAYARAN' + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01' + this.printinvoice.bukti + '\x1b\x4d\x01\x1b\x61\x00' + "\n\n"
        + '\x1b\x61\x00\x1b\x4d\x01TANGGAL : ' + this.printinvoice.date_trx + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01ID PEL  : ' + this.printinvoice.id_pel + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01NAMA   : ' + this.printinvoice.nama + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01TARIF DAYA  : ' + this.printinvoice.tarif_daya + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01Kwh : ' + this.printinvoice.kwh + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01REF  : ' + this.printinvoice.no_ref + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01NOMINAL : ' + this.printinvoice.nominal + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01BIAYA ADMIN : ' + this.printinvoice.biaya_admin + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01TOTAL BAYAR : ' + this.printinvoice.total_bayar + '\x1b\x4d\x01\x1b\x61\x00' + "\n\n"
        + '\x1b\x61\x00\x1b\x4d\x01TOKEN : ' + this.printinvoice.token + '\x1b\x4d\x01\x1b\x61\x00' + "\n\n"
        + '\x1b\x61\x01\x1b\x4d\x01 STRUK INI MERUPAKAN BUKTI TRANSAKSI YANG SAH, MOHON DISIMPAN \x1b\x4d\x01\x1b\x61\x01' + "\n\n"
        + '\x1b\x61\x01\x1b\x4d\x01' + this.printinvoice.informasi + '\x1b\x4d\x01\x1b\x61\x01' + "\n"
    } else if (this.printinvoice.informasi == "null" || this.printinvoice.informasi == "undefined") {
      var myText =
        '\x1b\x4d\x01\x1b\x61\x00KIOSDESA' + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01' + this.printinvoice.member_name + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01BUKTI PEMBAYARAN' + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01' + this.printinvoice.bukti + '\x1b\x4d\x01\x1b\x61\x00' + "\n\n"
        + '\x1b\x61\x00 \x1b\x45\x01TANGGAL : ' + this.printinvoice.date_trx + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00 \x1b\x45\x01ID PEL : ' + this.printinvoice.id_pel + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00 \x1b\x45\x01NAMA : ' + this.printinvoice.nama + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00 \x1b\x45\x01REF  : ' + this.printinvoice.no_ref + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00 \x1b\x45\x01TAGIHAN : ' + this.printinvoice.tagihan + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00 \x1b\x45\x01BIAYA ADMIN : ' + this.printinvoice.biaya_admin + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00 \x1b\x45\x01TOTAL BAYAR : ' + this.printinvoice.total_bayar + '\x1b\x4d\x01\x1b\x61\x00' + "\n\n"
        + '\x1b\x61\x01\x1b\x4d\x01 STRUK INI MERUPAKAN BUKTI TRANSAKSI YANG SAH, MOHON DISIMPAN \x1b\x4d\x01\x1b\x61\x01' + "\n"

    } else {
      var myText =
        '\x1b\x4d\x01\x1b\x61\x00KIOSDESA' + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01' + this.printinvoice.member_name + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01BUKTI PEMBAYARAN' + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00\x1b\x4d\x01' + this.printinvoice.bukti + '\x1b\x4d\x01\x1b\x61\x00' + "\n\n"
        + '\x1b\x61\x00 \x1b\x45\x01TANGGAL : ' + this.printinvoice.date_trx + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00 \x1b\x45\x01ID PEL : ' + this.printinvoice.id_pel + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00 \x1b\x45\x01NAMA : ' + this.printinvoice.nama + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00 \x1b\x45\x01REF  : ' + this.printinvoice.no_ref + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00 \x1b\x45\x01TAGIHAN : ' + this.printinvoice.tagihan + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00 \x1b\x45\x01BIAYA ADMIN : ' + this.printinvoice.biaya_admin + '\x1b\x4d\x01\x1b\x61\x00' + "\n"
        + '\x1b\x61\x00 \x1b\x45\x01TOTAL BAYAR : ' + this.printinvoice.total_bayar + '\x1b\x4d\x01\x1b\x61\x00' + "\n\n"
        + '\x1b\x61\x01\x1b\x4d\x01 STRUK INI MERUPAKAN BUKTI TRANSAKSI YANG SAH, MOHON DISIMPAN \x1b\x4d\x01\x1b\x61\x01' + "\n\n"
        + '\x1b\x61\x01\x1b\x4d\x01' + this.printinvoice.informasi + '\x1b\x4d\x01\x1b\x61\x01' + "\n"
    }

    this.print.sendToBluetoothPrinter(this.selectedPrinter, myText);
    this.popover.dismiss();
  }


  ClosePopover() {
    this.popover.dismiss();
  }
}
