import { Component,ViewChildren, QueryList } from '@angular/core';
import { Platform,ActionSheetController,PopoverController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
    lastTimeBackPress = new Date().getTime();
    timePeriodToExit = 1000;
    
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
    ) {
        this.initializeApp();
        this.backButtonEvent();
    }



    initializeApp() {
        this.platform.ready().then(() => {
        //this.statusBar.styleDefault();
        this.splashScreen.hide();
        });
    }

    backButtonEvent() {
        this.platform.backButton.subscribe(async () => {
            if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
                navigator['app'].exitApp(); 
            }
            this.lastTimeBackPress = new Date().getTime();
        });
    }

    
}
