import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { VoucherpopoverPageRoutingModule } from './voucherpopover-routing.module';

import { VoucherpopoverPage } from './voucherpopover.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    VoucherpopoverPageRoutingModule
  ],
  declarations: [VoucherpopoverPage]
})
export class VoucherpopoverPageModule { }
