import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ApiService, Helper } from 'src/app/kiosdesa.service';


@Component({
  selector: 'app-popover',
  templateUrl: './popover.page.html',
  styleUrls: ['./popover.page.scss'],
})
export class PopoverPage implements OnInit {

  constructor(private popover: PopoverController, public Router: Router, public ApiService: ApiService, public Helper: Helper) { }
  DECIMAL_SEPARATOR = ".";
  GROUP_SEPARATOR = ",";

  idpel: any = localStorage.getItem('idpel');
  nama: any = localStorage.getItem('namapel');
  status: any = localStorage.getItem('status');
  pokok: any = localStorage.getItem('pokok');
  denda: any = localStorage.getItem('denda');
  admin: any = localStorage.getItem('admin');
  period: any = localStorage.getItem('period');
  jml_bulan: any = localStorage.getItem('jml_bulan');
  tarif: any = localStorage.getItem('tarif');
  no_meter: any = localStorage.getItem('no_meter');
  meter: any = localStorage.getItem('meter');
  discount: any = localStorage.getItem('discount');
  total: any = localStorage.getItem('total');
  denom: any = localStorage.getItem('denom');


  ionViewWillEnter() {

    // console.log(this.denda);
    this.pokok = this.format(this.pokok);
    this.total = this.format(this.total);
    this.denda = this.format(this.denda);
    this.admin = this.format(this.admin);
    this.tarif = this.format(this.tarif);

  }
  ngOnInit() {

  }

  format(valString) {
    if (!valString) {
      return '';
    }
    let val = valString.toString();
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
    return parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!parts[1] ? '' : this.DECIMAL_SEPARATOR + parts[1]);
  };

  unFormat(val) {
    if (!val) {
      return '';
    }
    val = val.replace(/^0+/, '');

    if (this.GROUP_SEPARATOR === ',') {
      return val.replace(/,/g, '');
    } else {
      return val.replace(/\./g, '');
    }
  };



  buttonkonfirmasi() {
    localStorage.setItem('kondisiverificationcode', '0');
    this.Router.navigate(['verificationcode']);

    this.popover.dismiss();
  }

  ClosePopover() {
    this.popover.dismiss();
  }

}
